import { NavLink } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import { Path } from "../../resource/utils/PathUtils";
import styles from "../style/option.module.css";

const ArticleOption = () => {
  return (
    <NavLink
      to={Path.BASE}
      className={(props) => {
        const stateStyle = props.isActive ? styles.active : styles.inactive;
        return `${styles.option} ${stateStyle}`;
      }}
    >
      <ArticleIcon />
      articles
    </NavLink>
  );
};

export default ArticleOption;
