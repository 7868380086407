import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { ThemeProvider } from "@mui/material";
import ClearableTextField from "../component/ClearableTextField";
import SpeechTextField from "../component/SpeechTextField";
import RetailPeriodView from "../component/RetailPeriodView";
import CoverImagePicker from "../component/CoverImagePicker";
import AdditionalImagePicker from "../component/AdditionalImagePicker";
import ChromaImageView from "../component/ChromaImageView";
import ShowcaseView from "../component/ShowcaseView";
import ClearableSpeechLabel from "../component/ClearableSpeechLabel";
import { InputTheme } from "../../common/utils/ThemeUtils";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { createProduct } from "../client/AxiosClient";
import { Path } from "../utils/PathUtils";
import { buildMessageBundle, buildImage } from "../utils/Utils";
import styles from "../style/resource.module.css";

const CreateProductPage = () => {
  const [name, setName] = useState();
  const [nameLocales, setNameLocales] = useState([]);
  const [description, setDescription] = useState();
  const [descriptionLocales, setDescriptionLocales] = useState([]);
  const [cover, setCover] = useState();
  const [images, setImages] = useState([]);
  const [startRetailDate, setStartRetailDate] = useState();
  const [endRetailDate, setEndRetailDate] = useState();
  const [availability, setAvailability] = useState({});
  const [pricing, setPricing] = useState({});

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <label className={styles.label}>Resources</label>
        <div
          style={{
            display: "flex",
            width: "13vw",
            justifyContent: "space-between",
          }}
        >
          <NavLink
            to={Path.RESOURCE}
            className={`${styles.button} ${styles.createItemButton}`}
          >
            abort
          </NavLink>
          <NavLink
            to={"#"}
            className={`${styles.button} ${styles.createItemButton}`}
            onClick={() => {
              if (
                name === undefined ||
                availability?.shared === undefined ||
                pricing?.default === undefined
              ) {
                showDialog(
                  "Warning",
                  `Some required details for the product are incomplete. Please review
                       and ensure name, shared availability and default pricing fields are complete.`,
                  DialogScope.ERROR
                );
              } else {
                showLoading();
                const product = {
                  name: buildMessageBundle(name, nameLocales),
                  description: buildMessageBundle(
                    description,
                    descriptionLocales
                  ),
                  image: buildImage(cover, images),
                  startRetailDate: startRetailDate,
                  endRetailDate: endRetailDate,
                  availability: availability,
                  pricing: pricing,
                };
                createProduct(product)
                  .then(() => navigate(Path.RESOURCE))
                  .finally(hideLoading);
              }
            }}
          >
            create
          </NavLink>
        </div>
      </div>
      <Grid
        container
        spacing={3}
        sx={{ overflowY: "scroll" }}
        className={styles.content}
      >
        <ThemeProvider theme={InputTheme}>
          <Grid item sx={{ flex: 1 }} key={"general-column"}>
            <div className={styles.component}>
              <label className={styles.label}>GENERAL</label>
              {/*  product name section  */}
              <label className={styles.text}>Product Name</label>
              <ClearableTextField
                id="default-name-input"
                label="Default Product Name"
                onChange={setName}
              />
              {nameLocales.map((locale) => {
                return (
                  <ClearableSpeechLabel
                    speech={locale.speech}
                    text={locale.value}
                    onRemove={() =>
                      setNameLocales([
                        ...nameLocales.filter(
                          (it) => it.speech !== locale.speech
                        ),
                      ])
                    }
                  />
                );
              })}
              <SpeechTextField
                id="locale-name-input"
                onChange={(value) => {
                  setNameLocales([
                    ...nameLocales.filter((it) => it.speech !== value.speech),
                    value,
                  ]);
                }}
              />
              {/*  product description section  */}
              <label className={styles.text}>Product Description</label>
              <ClearableTextField
                id="default-description-input"
                label="Default Product Description"
                multiline={true}
                onChange={setDescription}
              />
              {descriptionLocales.map((locale) => {
                return (
                  <ClearableSpeechLabel
                    speech={locale.speech}
                    text={locale.value}
                    onRemove={() =>
                      setDescriptionLocales([
                        ...descriptionLocales.filter(
                          (it) => it.speech !== locale.speech
                        ),
                      ])
                    }
                  />
                );
              })}
              <SpeechTextField
                id="locale-description-input"
                multiline={true}
                onChange={(value) => {
                  setDescriptionLocales([
                    ...descriptionLocales.filter(
                      (it) => it.speech !== value.speech
                    ),
                    value,
                  ]);
                }}
              />
              {/*  product media section  */}
              <label className={styles.text}>Product Media</label>
              <div style={{ display: "flex", marginTop: "2vh" }}>
                <CoverImagePicker
                  src={cover}
                  onSelect={setCover}
                  onRemove={() => setCover(null)}
                />
                <Grid sx={{ flex: 1 }} container spacing={1}>
                  {images.map((image, index) => {
                    return [
                      <Grid item size={6} key={`additional-image-${index}`}>
                        <ChromaImageView
                          src={image}
                          defaultColor="#EEEEEE"
                          size="9vw"
                          onRemove={() =>
                            setImages(images.filter((it) => it !== image))
                          }
                        />
                      </Grid>,
                    ];
                  })}
                  <Grid item size={8} key={"additional-image-picker"}>
                    <AdditionalImagePicker
                      onSelect={(image) => setImages([...images, image])}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ marginTop: "3vh" }} className={styles.component}>
              <label className={styles.label}>RETAIL PERIOD</label>
              <RetailPeriodView
                onStartRetailDateSelect={setStartRetailDate}
                onEndRetailDateSelect={setEndRetailDate}
              />
            </div>
          </Grid>
          <Grid item size={7} key={"showcase-column"}>
            <div className={styles.component}>
              <label className={styles.label}>SHOWCASE</label>
              <ShowcaseView
                availability={{}}
                pricing={{}}
                onAvailabbilityChange={setAvailability}
                onPricingChange={setPricing}
              />
            </div>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

export default CreateProductPage;
