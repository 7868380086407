import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const NumericTextField = (props) => {
  const [value, setValue] = useState(props.value);

  return (
    <TextField
      id={props.id}
      value={value}
      label={props.label}
      variant="outlined"
      size="small"
      sx={{ flex: 1 }}
      slotProps={{
        htmlInput: {
          type: "number",
        },
        input: value && {
          endAdornment: (
            <IconButton
              size="small"
              style={{ marginRight: "-1vw" }}
              onClick={() => {
                setValue("");
                props.onChange("");
              }}
            >
              <ClearIcon size="small" />
            </IconButton>
          ),
        },
      }}
      onChange={(event) => {
        const { value } = event.target;
        setValue(value);
        props.onChange(value);
      }}
    />
  );
};

export default NumericTextField;
