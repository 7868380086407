import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ChromaView from "./ChromaView";
import styles from "../style/profile.module.css";

const PriceView = ({ price }) => {
  const renderSymbol = (symbol) => {
    if (symbol === "EUR") {
      return "€";
    } else if (symbol === "USD") {
      return "$";
    } else {
      return (
        <label style={{ fontSize: "0.8vw", paddingRight: 1 }}>{symbol}</label>
      );
    }
  };

  if (price) {
    const { currency, amount } = price;
    return (
      <div className={styles.pricing}>
        <LocalOfferOutlinedIcon fontSize="small" htmlColor="#0d6986" />
        <label className={styles.label}>
          {renderSymbol(currency)}
          {amount}
        </label>
      </div>
    );
  } else {
    return <div />;
  }
};

const OutOfStockView = ({ assets }) => {
  if (!assets || assets.amount === 0) {
    return <label className={styles.outOfStock}>out of stock</label>;
  }
};

const ItemView = (props) => {
  const { name, image } = props.item;

  if (image) {
    return (
      <NavLink to={"#"} className={styles.imageItem} onClick={props.onSelect}>
        <ChromaView src={image} defaultColor="#EEEEEE">
          <img
            style={{ maxWidth: "3vw", maxHeight: "3vw" }}
            src={image}
            alt=""
          />
        </ChromaView>
      </NavLink>
    );
  } else if (name) {
    return (
      <NavLink to={"#"} className={styles.textItem} onClick={props.onSelect}>
        {name.default}
      </NavLink>
    );
  }
};

const ProfileCardView = (props) => {
  const { name, items } = props.resource;
  const [image, setImage] = useState(props.resource?.image?.cover);
  const [assets, setAssets] = useState(props.resource.assets);
  const [price, setPrice] = useState(props.resource.price);

  return (
    <Grid item>
      <ChromaView src={image} defaultColor="#EEEEEE">
        <div className={styles.image}>
          <OutOfStockView assets={assets} />
          <img
            style={{ maxWidth: "13vw", maxHeight: "35vh" }}
            src={image}
            alt=""
          />
          <IconButton
            size="small"
            style={{
              position: "absolute",
              right: 3,
              top: 3,
              backgroundColor: "#fff",
              padding: 3,
            }}
          >
            <ZoomOutMapIcon htmlColor="#0d6986" fontSize="small" />
          </IconButton>
          <div className={styles.itemContent}>
            {items?.map((item) => {
              return (
                <ItemView
                  item={item}
                  onSelect={() => {
                    setImage(item.image || props.resource?.image?.cover);
                    setAssets(item.assets);
                    setPrice(item.price);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.content}>
          <label className={styles.name}>
            {`${name.default?.substring(0, 25)}${
              name.default?.length > 25 ? "..." : ""
            }`}
          </label>
          <div className={styles.control}>
            <PriceView price={price} />
            <Button
              variant="contained"
              disabled={
                price === undefined ||
                assets === undefined ||
                assets.amount === 0
              }
              sx={{
                boxShadow: "none",
                marginRight: "5px",
                textDecoration: "none",
                borderRadius: "5px",
                backgroundColor: "#0d6986",
                color: "white",
                fontWeight: "bold",
                padding: "5px",
                fontSize: "0.5vw",
                "&.MuiButtonBase-root:hover": {
                  boxShadow: "none",
                },
              }}
            >
              order
            </Button>
          </div>
        </div>
      </ChromaView>
    </Grid>
  );
};

export default ProfileCardView;
