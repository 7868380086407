import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import SummaryCardView from "../component/SummaryCardView";
import SearchView from "../../navigation/component/SearchView";
import { Path } from "../utils/PathUtils";
import { fetchResources } from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import styles from "../style/resource.module.css";

const ResourcesCardModeView = ({ resources }) => {
  if (resources) {
    if (resources.length === 0) {
      return (
        <div className={styles.empty}>
          <label className={styles.label}>
            It appears that you have not yet added any groups or products to the
            account. Add as many groups or goods as you want and start improving
            your business.
          </label>
        </div>
      );
    } else {
      return (
        <Grid container spacing={2} sx={{ flex: 1 }}>
          {resources.map((resource) => {
            return (
              <SummaryCardView
                name={resource.name.default}
                description={resource.description?.default}
                image={resource.image.cover}
                type={resource.items === undefined}
              />
            );
          })}
        </Grid>
      );
    }
  }
};

const ResourcePage = () => {
  const [isScrolledUp, setScrolledUp] = useState(true);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    showLoading();
    fetchResources().then(setResources).finally(hideLoading);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          height: isScrolledUp ? "3.5vw" : "0",
          transform: isScrolledUp ? "translateY(0)" : "translateY(-100%)",
          transition:
            "transform 0.3s ease-in, transform 0.3s ease-out, height 0.3s",
          transitionBehavior: "allow-discrete",
          padding: "0 1vw",
        }}
      >
        <label className={styles.label}>Resources</label>
        <div
          style={{
            width: "18vw",
            height: "3.5vw",
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <NavLink
            to={Path.CREATE_PRODUCT}
            className={`${styles.button} ${styles.createItemButton}`}
          >
            create product
          </NavLink>
          <NavLink
            to={Path.CREATE_GROUP}
            className={`${styles.button} ${styles.createItemButton}`}
          >
            create group
          </NavLink>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.control}>
          <div style={{ width: "20vw" }}>
            <SearchView onSearch={(phrase) => {}} onSearchCancel={() => {}} />
          </div>
        </div>
        <div
          className={styles.scrollableContent}
          onScroll={(event) => {
            if (event.currentTarget.scrollTop === 0) {
              setScrolledUp(true);
            } else {
              setScrolledUp(false);
            }
          }}
        >
          <ResourcesCardModeView resources={resources} />
        </div>
      </div>
    </div>
  );
};

export default ResourcePage;
