import { createSlice } from "@reduxjs/toolkit";
import { ViewMode } from "../utils/ViewUtils";

const propertySlice = createSlice({
  name: "property",
  initialState: {
    searchPhrase: undefined,
    viewMode: ViewMode.LIST,
    filter: {
      display: false,
      selection: undefined,
    },
  },
  reducers: {
    updateSearchPhrase(state, action) {
      state.searchPhrase = action.payload;
    },
    resetSearchPhrase(state) {
      state.searchPhrase = undefined;
    },
    listViewMode(state) {
      state.viewMode = ViewMode.LIST;
    },
    cardViewMode(state) {
      state.viewMode = ViewMode.CARD;
    },
    showFilter(state) {
      state.filter.display = true;
    },
    hideFilter(state) {
      state.filter.display = false;
    },
    defineSelection(state, action) {
      state.filter.selection = action.payload;
    },
  },
});

export const {
  updateSearchPhrase,
  resetSearchPhrase,
  listViewMode,
  cardViewMode,
  showFilter,
  hideFilter,
  defineSelection,
} = propertySlice.actions;

export default propertySlice.reducer;
