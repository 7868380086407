import DashboardOption from "./DashboardOption";
import ArticleOption from "./ArticleOption";
import ChecklistOption from "./ChecklistOption";
import OrderOption from "./OrderOption";
import TeamOption from "./TeamOption";
import ContactOption from "./ContactOption";
import LanguageOption from "./LanguageOption";
import AlertOption from "./AlertOption";
import SettingsOption from "./SettingsOption";
import InfoOption from "./InfoOption";
import LogoutOption from "./LogoutOption";
import { ReactComponent as ShadworkLogo } from "../../common/assets/shadwork-logo.svg";
import styles from "../style/panel.module.css";

const NavigationPanelView = () => {
  return (
    <div className={styles.panel}>
      <div className={styles.logo}>
        <ShadworkLogo width="2vw" style={{ marginBottom: "1vh" }} />
      </div>
      <div className={styles.navigation}>
        <DashboardOption />
        <ArticleOption />
        <ChecklistOption />
        <OrderOption />
        <TeamOption />
        <ContactOption />
      </div>
      <div className={styles.control}>
        <LanguageOption />
        <AlertOption />
        <SettingsOption />
        <InfoOption />
        <LogoutOption />
      </div>
    </div>
  );
};

export default NavigationPanelView;