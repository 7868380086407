import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Fade, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import CheckIcon from "@mui/icons-material/Check";

const SpeechTextField = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");
  const [speech, setSpeech] = useState("");

  const handleSpeechClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSpeechClose = () => {
    setAnchorEl(null);
  };

  return (
    <TextField
      id={props.id}
      value={value}
      label={props.label}
      multiline={props.multiline}
      variant="outlined"
      size="small"
      disabled={!speech}
      sx={{ width: "28vw", marginTop: "2vh" }}
      slotProps={{
        input: {
          startAdornment: (
            <div>
              <IconButton size="small" onClick={handleSpeechClick}>
                {speech ? (
                  <label>{speech}</label>
                ) : (
                  <GTranslateIcon size="small" />
                )}
              </IconButton>
              <Menu
                id="fade-menu"
                MenuListProps={{ "aria-labelledby": "fade-button" }}
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleSpeechClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    handleSpeechClose();
                    setSpeech("en");
                  }}
                >
                  en
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSpeechClose();
                    setSpeech("ro");
                  }}
                >
                  ro
                </MenuItem>
              </Menu>
            </div>
          ),
          endAdornment: (
            <IconButton
              disabled={!value}
              size="small"
              onClick={() => {
                props.onChange({speech, value});
                setSpeech("");
                setValue("");
              }}
            >
              <CheckIcon size="small" />
            </IconButton>
          ),
        },
      }}
      onChange={(event) => {
        const { value } = event.target;
        setValue(value);
        if (!value) {
          setSpeech("");
        }
      }}
    />
  );
};

export default SpeechTextField;
