import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import SearchView from "../../navigation/component/SearchView";
import ViewModeButton from "../component/ViewModeButton";
import partnershipSlice from "../redux/partnershipSlice";
import propertySlice, {
  resetSearchPhrase,
  updateSearchPhrase,
} from "../redux/propertySlice";
import styles from "../style/partnership.module.css";

/**
 * Using a separate component to enhance the page content because the default
 * component has to define the store only when the page is loaded. Because of
 * dynamic rendering of elements inside the page, the component is re-rendered
 * on every scroll event, which causes store to be recreated as well.
 */
const PartnershipPageContent = () => {
  const dispatch = useDispatch();
  const [isScrolledUp, setScrolledUp] = useState(true);

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          height: isScrolledUp ? "3.5vw" : "0",
          transform: isScrolledUp ? "translateY(0)" : "translateY(-100%)",
          transition:
            "transform 0.3s ease-in, transform 0.3s ease-out, height 0.3s",
          transitionBehavior: "allow-discrete",
          paddingLeft: "1vw",
        }}
      >
        <label className={styles.label}>Contacts</label>
      </div>
      <div className={styles.content}>
        <div
          style={{ display: "flex", marginBottom: !isScrolledUp ? "1vw" : 0 }}
        >
          <NavLink
            to={"/partnership/account"}
            className={(props) => {
              const stateStyle = props.isActive
                ? styles.activeNavLink
                : styles.inactiveNavLink;
              return `${styles.navigationLink} ${stateStyle}`;
            }}
          >
            Accounts
          </NavLink>
          <div style={{ borderBottom: "2px solid #7FC6E6", flex: 1 }} />
          <NavLink
            to={"/partnership/contact"}
            className={(props) => {
              const stateStyle = props.isActive
                ? styles.activeNavLink
                : styles.inactiveNavLink;
              return `${styles.navigationLink} ${stateStyle}`;
            }}
          >
            Contacts
          </NavLink>
          <div style={{ borderBottom: "2px solid #7FC6E6", flex: 1 }} />
          <NavLink
            to={"/partnership/request"}
            className={(props) => {
              const stateStyle = props.isActive
                ? styles.activeNavLink
                : styles.inactiveNavLink;
              return `${styles.navigationLink} ${stateStyle}`;
            }}
          >
            Requests
          </NavLink>
          <div style={{ borderBottom: "2px solid #7FC6E6", width: "75vw" }} />
        </div>
        <div
          className={styles.controlPanel}
          style={{
            display: isScrolledUp ? "flex" : "none",
            transform: isScrolledUp ? "translateY(0)" : "translateY(-1vh)",
            transition:
              "transform 0.3s ease-in, transform 0.3s ease-out, display 0.3s",
            transitionBehavior: "allow-discrete",
          }}
        >
          <div style={{ width: "30vw" }}>
            <SearchView
              onSearch={(phrase) => dispatch(updateSearchPhrase(phrase))}
              onSearchCancel={() => dispatch(resetSearchPhrase())}
            />
          </div>
          <div style={{ display: "flex" }}>
            <ViewModeButton />
          </div>
        </div>
        <div
          className={styles.scrollableContent}
          onScroll={(event) => {
            if (event.currentTarget.scrollTop === 0) {
              setScrolledUp(true);
            } else {
              setScrolledUp(false);
            }
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const PartnershipPage = () => {
  const store = configureStore({
    reducer: {
      partnership: partnershipSlice,
      property: propertySlice,
    },
  });

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <Provider store={store}>
        <PartnershipPageContent />
      </Provider>
    </div>
  );
};

export default PartnershipPage;
