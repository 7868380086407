import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { ThemeProvider, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import ClearableTextField from "../component/ClearableTextField";
import SpeechTextField from "../component/SpeechTextField";
import RetailPeriodView from "../component/RetailPeriodView";
import CoverImagePicker from "../component/CoverImagePicker";
import AdditionalImagePicker from "../component/AdditionalImagePicker";
import ChromaImageView from "../component/ChromaImageView";
import ClearableSpeechLabel from "../component/ClearableSpeechLabel";
import SearchView from "../../navigation/component/SearchView";
import ManageItemDialog from "../component/ManageItemDialog";
import {
  DialogScope,
  showDialog,
} from "../../common/dialog/GenericScopeDialog";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { buildImage, buildMessageBundle } from "../utils/Utils";
import { createGroup } from "../client/AxiosClient";
import { Path } from "../utils/PathUtils";
import { InputTheme } from "../../common/utils/ThemeUtils";
import styles from "../style/resource.module.css";
import itemStyles from "../style/item.module.css";

const calculateAllocations = (allocations) => {
  if (allocations) {
    let amount = 0;
    allocations
      .map((allocation) => Number(allocation.assets.amount))
      .forEach((allocation) => (amount = amount + allocation));
    return amount;
  } else {
    return "0";
  }
};

const MessageTooltip = (props) => {
  const containLocales = () => {
    return props.message?.bundles?.length > 0;
  };

  const renderTitle = () => {
    if (containLocales()) {
      return `This field has locale-specific translations. The appropriate 
        translation will be displayed based on the selected language or region.`;
    } else {
      return `This field does not have a locale-specific translation.
        A generic version will be displayed instead.`;
    }
  };

  return (
    <Tooltip
      sx={{ marginLeft: "5px" }}
      arrow
      title={renderTitle()}
      placement="right"
    >
      <GTranslateIcon
        htmlColor={containLocales() ? "#0D6986" : "#BBBBBB"}
        fontSize={"small"}
      />
    </Tooltip>
  );
};

const MessageLabel = ({ message, defaultMessage }) => {
  return [
    <label
      style={{ color: message?.default ? "#0D6986" : "#BBBBBB" }}
      className={itemStyles.message}
    >
      {message?.default || defaultMessage}
    </label>,
    <MessageTooltip message={message} />,
  ];
};

const HeaderCell = (props) => {
  return (
    <Grid
      item
      size={props.size}
      key={`header-cell-${props.value}`}
      className={itemStyles.header}
    >
      {props.value}
    </Grid>
  );
};

const MessageCell = (props) => {
  return (
    <Grid
      item
      size={props.size}
      key={`cell-${props.message}`}
      sx={{ display: "flex", paddingLeft: "5px" }}
    >
      <MessageLabel
        message={props.message}
        defaultMessage={props.defaultMessage}
      />
    </Grid>
  );
};

const LabelCell = (props) => {
  return (
    <Grid
      item
      size={15}
      key="cell-pricing"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        paddingLeft: "5px",
      }}
    >
      {props.children}
    </Grid>
  );
};

const SharedAssetsLabel = (props) => {
  const { shared } = props.availability;

  return (
    <label
      style={{ color: shared ? "#0D6986" : "#BBBBBB" }}
      className={itemStyles.hintLabel}
    >
      {`shared: ${shared?.amount || "0"}`}
    </label>
  );
};

const ReservedAssetsLabel = (props) => {
  const { allocations } = props.availability;

  return (
    <label
      style={{ color: allocations ? "#0D6986" : "#BBBBBB" }}
      className={itemStyles.hintLabel}
    >
      {`reserved: ${calculateAllocations(allocations)}`}
    </label>
  );
};

const DefaultPricingLabel = (props) => {
  const { amount, currency } = props.pricing?.default;

  return (
    <label
      style={{ color: amount ? "#0D6986" : "#BBBBBB" }}
      className={itemStyles.hintLabel}
    >
      {amount ? `default: ${amount} ${currency}` : "-"}
    </label>
  );
};

const ExclusivePricingLabel = (props) => {
  const { exclusives } = props.pricing;

  return (
    <label
      style={{ color: exclusives ? "#0D6986" : "#BBBBBB" }}
      className={itemStyles.hintLabel}
    >
      exclusives: {exclusives?.length || "0"}
    </label>
  );
};

const CreateGroupPage = () => {
  const [phrase, setPhrase] = useState();
  const [name, setName] = useState();
  const [nameLocales, setNameLocales] = useState([]);
  const [description, setDescription] = useState();
  const [descriptionLocales, setDescriptionLocales] = useState([]);
  const [cover, setCover] = useState();
  const [images, setImages] = useState([]);
  const [startRetailDate, setStartRetailDate] = useState();
  const [endRetailDate, setEndRetailDate] = useState();
  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <label className={styles.label}>Resources</label>
        <div
          style={{
            display: "flex",
            width: "13vw",
            justifyContent: "space-between",
          }}
        >
          <NavLink
            to={Path.RESOURCE}
            className={`${styles.button} ${styles.createItemButton}`}
          >
            abort
          </NavLink>
          <NavLink
            to={"#"}
            className={`${styles.button} ${styles.createItemButton}`}
            onClick={() => {
              if (name === undefined || items.length === 0) {
                showDialog(
                  "Warning",
                  `Some required details for the group are incomplete. 
                Please review and ensure name field and entities are complete.`,
                  DialogScope.ERROR
                );
              } else {
                showLoading();
                const product = {
                  name: buildMessageBundle(name, nameLocales),
                  description: buildMessageBundle(
                    description,
                    descriptionLocales
                  ),
                  image: buildImage(cover, images),
                  startRetailDate: startRetailDate,
                  endRetailDate: endRetailDate,
                  items: items,
                };
                createGroup(product)
                  .then(() => navigate(Path.RESOURCE))
                  .finally(hideLoading);
              }
            }}
          >
            create
          </NavLink>
        </div>
      </div>
      <Grid
        container
        spacing={3}
        sx={{ overflowY: "scroll" }}
        className={styles.content}
      >
        <ThemeProvider theme={InputTheme}>
          <Grid item sx={{ flex: 1 }} key={"general-column"}>
            <div className={styles.component}>
              <label className={styles.label}>GENERAL</label>
              {/*  group name section  */}
              <label className={styles.text}>Group Name</label>
              <ClearableTextField
                id="default-name-input"
                label="Default Group Name"
                onChange={setName}
              />
              {nameLocales.map((locale) => {
                return (
                  <ClearableSpeechLabel
                    speech={locale.speech}
                    text={locale.value}
                    onRemove={() =>
                      setNameLocales([
                        ...nameLocales.filter(
                          (it) => it.speech !== locale.speech
                        ),
                      ])
                    }
                  />
                );
              })}
              <SpeechTextField
                id="locale-name-input"
                onChange={(value) => {
                  setNameLocales([
                    ...nameLocales.filter((it) => it.speech !== value.speech),
                    value,
                  ]);
                }}
              />
              {/*  group description section  */}
              <label className={styles.text}>Group Description</label>
              <ClearableTextField
                id="default-description-input"
                label="Default Group Description"
                multiline={true}
                onChange={setDescription}
              />
              {descriptionLocales.map((locale) => {
                return (
                  <ClearableSpeechLabel
                    speech={locale.speech}
                    text={locale.value}
                    onRemove={() =>
                      setDescriptionLocales([
                        ...descriptionLocales.filter(
                          (it) => it.speech !== locale.speech
                        ),
                      ])
                    }
                  />
                );
              })}
              <SpeechTextField
                id="locale-description-input"
                multiline={true}
                onChange={(value) => {
                  setDescriptionLocales([
                    ...descriptionLocales.filter(
                      (it) => it.speech !== value.speech
                    ),
                    value,
                  ]);
                }}
              />
              {/*  group media section  */}
              <label className={styles.text}>Group Media</label>
              <div style={{ display: "flex", marginTop: "2vh" }}>
                <CoverImagePicker
                  src={cover}
                  onSelect={setCover}
                  onRemove={() => setCover(null)}
                />
                <Grid sx={{ flex: 1 }} container spacing={1}>
                  {images.map((image, index) => {
                    return [
                      <Grid item size={6} key={`additional-image-${index}`}>
                        <ChromaImageView
                          src={image}
                          defaultColor="#EEEEEE"
                          size="9vw"
                          onRemove={() =>
                            setImages(images.filter((it) => it !== image))
                          }
                        />
                      </Grid>,
                    ];
                  })}
                  <Grid item size={8} key={"additional-image-picker"}>
                    <AdditionalImagePicker
                      onSelect={(image) => setImages([...images, image])}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ marginTop: "3vh" }} className={styles.component}>
              <label className={styles.label}>RETAIL PERIOD</label>
              <RetailPeriodView
                onStartRetailDateSelect={setStartRetailDate}
                onEndRetailDateSelect={setEndRetailDate}
              />
            </div>
          </Grid>
          <Grid item size={7} key={"items-column"}>
            <div className={styles.component}>
              <label className={styles.label}>ENTITIES</label>
              <div className={styles.control}>
                <div style={{ width: "20vw" }}>
                  <SearchView
                    onSearch={setPhrase}
                    onSearchCancel={() => setPhrase(undefined)}
                  />
                </div>
                <div style={{ display: "flex" }} />
              </div>
              <Grid sx={{ flex: 1 }} container columns={100} spacing={0.1}>
                <HeaderCell size={25} value={"Name"} />
                <HeaderCell size={30} value={"Description"} />
                <HeaderCell size={5} />
                <HeaderCell size={15} value={"Availability"} />
                <HeaderCell size={15} value={"Pricing"} />
                <HeaderCell size={10} />
                {items.map((item) => {
                  const { name, description, image, availability, pricing } =
                    item;
                  return [
                    <MessageCell
                      size={25}
                      message={name}
                      defaultMessage="no name"
                    />,
                    <MessageCell
                      size={30}
                      message={description}
                      defaultMessage="no description"
                    />,
                    <Grid
                      item
                      size={5}
                      key="cell-image-indicator"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <ImageOutlinedIcon
                        htmlColor={image ? "#0D6986" : "#BBBBBB"}
                        size="small"
                      />
                    </Grid>,
                    <LabelCell>
                      <SharedAssetsLabel availability={availability} />
                      <ReservedAssetsLabel availability={availability} />
                    </LabelCell>,
                    <LabelCell>
                      <DefaultPricingLabel pricing={pricing} />
                      <ExclusivePricingLabel pricing={pricing} />
                    </LabelCell>,
                    <Grid
                      item
                      size={10}
                      key="cell-controls"
                      sx={{ display: "flex" }}
                    >
                      <IconButton
                        size="small"
                        onClick={() =>
                          setItems(items.filter((it) => it !== item))
                        }
                      >
                        <DeleteForeverOutlinedIcon
                          htmlColor="#0D6986"
                          size="small"
                        />
                      </IconButton>
                      <ManageItemDialog item={item} onClick={() => {}}>
                        <IconButton size="small">
                          <EditOutlinedIcon htmlColor="#0D6986" size="small" />
                        </IconButton>
                      </ManageItemDialog>
                    </Grid>,
                    <Grid item size={100} sx={{ margin: "1vh 0" }}>
                      <div className={itemStyles.separator} />
                    </Grid>,
                  ];
                })}
                <Grid
                  item
                  size={100}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "2vh 0",
                  }}
                >
                  <ManageItemDialog
                    onClick={(item) => setItems([...items, item])}
                  >
                    <NavLink to={"#"} className={itemStyles.button}>
                      add entity
                    </NavLink>
                  </ManageItemDialog>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

export default CreateGroupPage;
