import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import SearchView from "../../navigation/component/SearchView";
import CurrencyTextField from "./CurrencyTextField";
import NumericTextField from "./NumericTextField";
import { phraseMatches } from "../../common/utils/SearchUtils";
import { fetchActive } from "../../common/client/PartnershipClient";
import styles from "../style/showcase.module.css";

const HeaderCell = ({ label }) => {
  return (
    <Grid item size={4} key={`header-cell-${label}`} className={styles.header}>
      {label}
    </Grid>
  );
};

const LabelCell = ({ name, image }) => {
  return (
    <Grid
      sx={{ display: "flex", alignItems: "center", paddingLeft: "5px" }}
      item
      size={4}
      key={`column-cell-${name}`}
    >
      {image && (
        <img
          src={image}
          style={{ maxWidth: "4vw", maxHeight: "4vh", marginRight: "1vw" }}
          alt=""
        />
      )}
      <label className={styles.label}>{name}</label>
    </Grid>
  );
};

const InputCell = (props) => {
  return (
    <Grid item size={4} sx={{ display: "flex" }} key={props.key}>
      {props.children}
    </Grid>
  );
};

const ShowcaseView = (props) => {
  const { availability, pricing } = props;
  const [phrase, setPhrase] = useState();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    fetchActive().then((contacts) => {
      setAccounts(contacts.map((contact) => contact.account));
    });
  }, []);

  return (
    <div>
      <div className={styles.control}>
        <div style={{ width: "20vw" }}>
          <SearchView
            onSearch={setPhrase}
            onSearchCancel={() => setPhrase(undefined)}
          />
        </div>
        <div style={{ display: "flex" }} />
      </div>
      <Grid sx={{ flex: 1, marginTop: "1vh" }} container spacing={1}>
        <HeaderCell label="Account" />
        <HeaderCell label="Availability" />
        <HeaderCell label="Pricing" />
        <LabelCell name="General" image="" />
        <InputCell key="shared-availability-cell">
          <NumericTextField
            value={availability?.shared?.amount}
            onChange={(amount) => {
              if (amount) {
                availability.shared = { amount: amount, unit: "qty" };
              } else {
                availability.shared = undefined;
              }
              props.onAvailabbilityChange(availability);
            }}
          />
        </InputCell>
        <InputCell key="default-pricing-cell">
          <CurrencyTextField
            amount={pricing?.default?.amount}
            currency={pricing?.default?.currency}
            onChange={(amount, currency) => {
              if (amount) {
                pricing.default = { amount, currency };
              } else {
                pricing.default = undefined;
              }
              props.onPricingChange(pricing);
            }}
          />
        </InputCell>
        <Grid item size={12}>
          <div className={styles.separator} />
        </Grid>
        {accounts
          .filter((account) => {
            if (phrase) {
              return phraseMatches(account.name, phrase);
            } else {
              return true;
            }
          })
          .map(({ uuid, name, logo }) => {
            const assets = props.availability?.allocations?.find(
              (it) => (it.subject = uuid)
            )?.assets;
            const price = props.pricing?.exclusives?.find(
              (it) => (it.subject = uuid)
            )?.price;
            return [
              <LabelCell name={name} image={logo} />,
              <InputCell key={`availability-cell-${name}`}>
                <NumericTextField
                  value={assets?.amount}
                  onChange={(amount) => {
                    if (amount) {
                      const allocations =
                        availability.allocations?.filter(
                          (it) => it.subject !== uuid
                        ) || [];
                      allocations.push({
                        subject: uuid,
                        assets: { amount: amount, unit: "qty" },
                      });
                      availability.allocations = allocations;
                      props.onAvailabbilityChange(availability);
                    } else {
                      availability.allocations =
                        availability.allocations?.filter(
                          (it) => it.subject !== uuid
                        );
                      props.onAvailabbilityChange(availability);
                    }
                  }}
                />
              </InputCell>,
              <InputCell key={`pricing-cell-${name}`}>
                <CurrencyTextField
                  amount={price?.amount}
                  currency={price?.currency}
                  onChange={(amount, currency) => {
                    if (amount) {
                      const exclusives =
                        pricing.exclusives?.filter(
                          (it) => it.subject !== uuid
                        ) || [];
                      exclusives.push({
                        subject: uuid,
                        price: { amount, currency },
                      });
                      pricing.exclusives = exclusives;
                    } else {
                      pricing.exclusives = pricing.exclusives?.filter(
                        (it) => it.subject !== uuid
                      );
                    }
                    props.onPricingChange(pricing);
                  }}
                />
              </InputCell>,
              <Grid item size={12}>
                <div className={styles.separator} />
              </Grid>,
            ];
          })}
      </Grid>
    </div>
  );
};

export default ShowcaseView;
