import { useState } from "react";
import { NavLink } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import styles from "../style/image.module.css";

const AdditionalImagePicker = (props) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);

  return (
    <div className={styles.additional}>
      <Dialog width={"50vw"} open={open} onClose={() => setOpen(false)}>
        <DialogContent sx={{ width: "40vw" }}>
          <TextField
            sx={{ width: "39vw" }}
            variant="standard"
            label="Additional image url"
            onChange={(event) => {
              setImage(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold" }}
            onClick={() => {
              setOpen(false);
              if (image) props.onSelect(image);
            }}
          >
            select
          </Button>
        </DialogActions>
      </Dialog>
      <NavLink
        to={"#"}
        className={styles.picker}
        onClick={() => setOpen(true)}
      >
        load image
      </NavLink>
    </div>
  );
};

export default AdditionalImagePicker;
