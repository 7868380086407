import axiosClient, {
  executeGetJsonStreamRequest,
} from "../../common/client/AxiosClient";
import { authorisationToken } from "../../common/client/AuthClient";

export const createProduct = (product) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/product", product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const createGroup = (group) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/resource/group", group, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const fetchResources = () => {
  return executeGetJsonStreamRequest("/resource/summary");
};

export const searchResources = () => {
  return executeGetJsonStreamRequest("/resource/search");
};