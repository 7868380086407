import ChromaView from "./ChromaView";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "../style/image.module.css";

const ChromaImageView = (props) => {
  return (
    <ChromaView src={props.src} defaultColor="#EEEEEE">
      <div
        className={styles.chroma}
        style={{
          width: props.size,
          height: props.size,
        }}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          onClick={props.onRemove}
        >
          <ClearIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
        <img
          style={{ maxWidth: props.size, maxHeight: props.size }}
          src={props.src}
          alt=""
        />
      </div>
    </ChromaView>
  );
};

export default ChromaImageView;
