import React from "react";
import { Outlet } from "react-router-dom";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import styles from "../style/resource.module.css";

const ResourceWrapperPage = () => {
  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <Outlet />
    </div>
  );
};

export default ResourceWrapperPage;
