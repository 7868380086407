import React, { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const SearchView = (props) => {
  const [phrase, setPhrase] = useState("");
  const searchInputRef = useRef();

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        height: "2vw",
        border: "1px solid #707070",
        borderRadius: 10,
        backgroundColor: "#ffffff",
      }}
    >
      <IconButton
        sx={{
          width: "2vw",
          height: "1.5vw",
        }}
        onClick={() => phrase?.length > 0 && props.onSearch(phrase)}
      >
        <SearchIcon
          sx={{
            width: "1.5vw",
          }}
          htmlColor="#707070"
        />
      </IconButton>
      <input
        value={phrase}
        ref={searchInputRef}
        placeholder="Search"
        onChange={(event) => {
          const value = event.target.value;
          setPhrase(value);
          if (props.onSearchTyping) {
            props.onSearchTyping(value);
          }
        }}
        style={{ all: "unset", fontStyle: "italic", flex: 1, fontSize: "1vw" }}
      />
      {phrase && (
        <IconButton
          size="small"
          onClick={() => {
            setPhrase("");
            searchInputRef.current.value = "";
            if (props.onSearchCancel) {
              props.onSearchCancel();
            }
          }}
        >
          <CloseIcon htmlColor="#707070" />
        </IconButton>
      )}
    </div>
  );
};

export default SearchView;
