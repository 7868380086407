import React, { useState } from "react";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as ErrorRobotImage } from "./assets/error-assistant-robot.svg";
import { ReactComponent as CongratsRobotImage } from "./assets/congrats-assistant-robot.svg";

Modal.setAppElement("#root");

const slice = createSlice({
  name: "genericDialogMessage",
  initialState: {
    title: undefined,
    message: undefined,
    image: undefined,
  },
  reducers: {
    setupContent(state, action) {
      const { title, message, image } = action.payload;
      state.title = title;
      state.message = message;
      state.image = image;
    },
  },
});

const triggerResetSlice = createSlice({
  name: "triggerResetDialog",
  initialState: {
    triggerTime: undefined,
  },
  reducers: {
    triggerReset(state) {
      state.triggerTime = Date.now();
    },
  },
});

const store = configureStore({
  reducer: {
    genericDialogMessage: slice.reducer,
  },
});

const triggerResetStore = configureStore({
  reducer: {
    triggerResetDialog: triggerResetSlice.reducer,
  },
});

const showDialog = (title, message, image) => {
  store.dispatch(
    slice.actions.setupContent({
      title: title,
      message: message,
      image: image,
    })
  );
};

const showCallableDialog = (title, message, scope) => {
  showDialog(title, message, scope);
  return new Promise((resolve) => {
    triggerResetStore.subscribe(() => {
      resolve();
    });
  });
};

const DialogScope = Object.freeze({
  ERROR: "ERROR",
  CONGRATS: "CONGRATS",
});

const resolveImage = (scope) => {
  if (scope === DialogScope.ERROR) {
    return <ErrorRobotImage />;
  } else if (scope === DialogScope.CONGRATS) {
    return <CongratsRobotImage />;
  } else {
    return undefined;
  }
};

const contentStyle = {
  content: {
    top: "15vh",
    bottom: "15vh",
    left: "20vw",
    right: "20vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: "5vh",
    paddingBottom: "5vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  overlay: {
    zIndex: 10,
  },
};

const closeStyle = {
  position: "absolute",
  right: "1vh",
  top: "1vh",
};

const GenericScopeDialog = () => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [image, setImage] = useState();

  store.subscribe(() => {
    const { title, message, image } = store.getState().genericDialogMessage;
    setTitle(title);
    setMessage(message);
    setImage(image);
    setVisible(true);
  });

  const onClose = () => {
    setTitle(undefined);
    setMessage(undefined);
    setImage(undefined);
    setVisible(false);
    triggerResetStore.dispatch(triggerResetSlice.actions.triggerReset());
  };

  return (
    <Modal isOpen={visible} style={contentStyle} onRequestClose={onClose}>
      <IconButton style={closeStyle} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      {resolveImage(image)}
      <label
        style={{
          fontSize: "3vw",
          fontWeight: "bold",
          color: "#0D6986",
          marginTop: "2vh",
        }}
      >
        {title}
      </label>
      <label
        style={{
          fontSize: "1.5vw",
          color: "#0D6986",
          textAlign: "center",
          marginTop: "1vh",
        }}
      >
        {message}
      </label>
    </Modal>
  );
};

export { showDialog, showCallableDialog, DialogScope };

export default GenericScopeDialog;
