import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const ClearableSpeechLabel = ({ speech, text, onRemove }) => {
  return (
    <div
      style={{
        width: "27.5vw",
        height: "5vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 5,
        marginTop: "1vh",
        border: "1.5px solid #0D6986",
        borderRadius: 5,
      }}
    >
      <label>
        {speech} {text}
      </label>
      <IconButton size="small" onClick={onRemove}>
        <ClearIcon size="small" />
      </IconButton>
    </div>
  );
};

export default ClearableSpeechLabel;
