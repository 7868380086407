import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Fade, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import ClearIcon from "@mui/icons-material/Clear";

const CurrencyTextField = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(props.amount);
  const [currency, setCurrency] = useState(props.currency);

  const handleSpeechClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSpeechClose = () => {
    setAnchorEl(null);
  };

  const currencySymbol = () => {
    let symbol = currency;
    if (currency === "EUR") {
      symbol = "€";
    } else if (currency === "USD") {
      symbol = "$";
    }
    return <div style={{ marginRight: 5 }}>{symbol}</div>;
  };

  return (
    <TextField
      id={props.id}
      value={value}
      label={props.label}
      multiline={props.multiline}
      variant="outlined"
      size="small"
      disabled={!currency}
      sx={{ flex: 1 }}
      slotProps={{
        htmlInput: { type: "number" },
        input: {
          startAdornment: currency && currencySymbol(),
          endAdornment: (
            <div style={{ marginRight: "-1vw" }}>
              {currency ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setValue("");
                    setCurrency("");
                    props.onChange(undefined, undefined);
                  }}
                >
                  <ClearIcon size="small" />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={handleSpeechClick}>
                  <SellOutlinedIcon size="small" />
                </IconButton>
              )}
              <Menu
                id="fade-menu"
                MenuListProps={{ "aria-labelledby": "fade-button" }}
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleSpeechClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    handleSpeechClose();
                    setCurrency("EUR");
                  }}
                >
                  EUR
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSpeechClose();
                    setCurrency("USD");
                  }}
                >
                  USD
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSpeechClose();
                    setCurrency("RON");
                  }}
                >
                  RON
                </MenuItem>
              </Menu>
            </div>
          ),
        },
      }}
      onChange={(event) => {
        const { value } = event.target;
        setValue(value);
        if (!value) {
          setCurrency("");
        }
        props.onChange(value, currency);
      }}
    />
  );
};

export default CurrencyTextField;
