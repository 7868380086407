import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import EmptyPageView from "../component/EmptyPageView";
import ScrollableListView from "../component/ScrollableListView";
import ControlButtonView from "../component/ControlButtonView";
import CardDisplayView from "../component/CardDisplayView";
import {
  addAccounts,
  addRequest,
  excludeAccount,
} from "../redux/partnershipSlice";
import {
  fetchAccounts,
  sendEstablishContactRequest,
} from "../client/AxiosClient";
import { phraseMatches } from "../../common/utils/SearchUtils";
import {
  showLoading,
  hideLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { ViewMode } from "../utils/ViewUtils";
import styles from "../style/partnership.module.css";

const AccountPage = () => {
  const dispatch = useDispatch();
  const { viewMode, searchPhrase } = useSelector((state) => state.property);
  const { accounts } = useSelector((state) => state.partnership);

  useEffect(() => {
    if (!accounts) {
      showLoading();
      fetchAccounts()
        .then((data) => dispatch(addAccounts(data)))
        .finally(hideLoading);
    }
  });

  const ControlButton = (props) => {
    const { account, fontSize } = props;
    return (
      <ControlButtonView
        title="Proceed with connection request"
        message={`
          You're about to send a connection request to "${account.name}". Once sent, 
          this request cannot be undone. The account will be notified and can decide 
          whether to accept or decline your connection request.
        `}
        onClick={() => {
          showLoading();
          sendEstablishContactRequest(account.uuid)
            .then((uuid) => {
              dispatch(excludeAccount(account.uuid));
              dispatch(
                addRequest({
                  uuid: uuid,
                  active: false,
                  editable: false,
                  account: account,
                })
              );
            })
            .finally(hideLoading);
        }}
      >
        <AddBoxOutlinedIcon fontSize={fontSize} htmlColor="#0D6986" />
      </ControlButtonView>
    );
  };

  if (accounts) {
    const actualAccounts =
      searchPhrase !== undefined
        ? accounts.filter((account) =>
            phraseMatches(account.name, searchPhrase)
          )
        : accounts;
    if (actualAccounts.length > 0) {
      if (viewMode === ViewMode.LIST) {
        return (
          <ScrollableListView>
            {actualAccounts.map((account) => {
              return (
                <div className={styles.listViewContent}>
                  <div className={styles.listViewDetails}>
                    <div className={styles.listViewLogo}>
                      <img
                        className={styles.listViewImage}
                        src={account.logo}
                        alt={""}
                      />
                    </div>
                    <div className={styles.listViewName}>
                      <label className={styles.viewItemLabel}>
                        {account.name}
                      </label>
                    </div>
                  </div>
                  <ControlButton account={account} fontSize="medium" />
                </div>
              );
            })}
          </ScrollableListView>
        );
      } else if (viewMode === ViewMode.CARD) {
        return (
          <CardDisplayView>
            {actualAccounts.map((account) => {
              return (
                <Grid
                  className={styles.cardViewContent}
                  item
                  size={2}
                  key={"account.uuid"}
                >
                  <div style={{ flex: 1, display: "flex" }}>
                    <div className={styles.cardViewControls} />
                    <div className={styles.cardViewLogo}>
                      <img
                        className={styles.cardViewImage}
                        src={account.logo}
                        alt={""}
                      />
                    </div>
                    <div className={styles.cardViewControls}>
                      <ControlButton fontSize="small" account={account} />
                    </div>
                  </div>
                  <div style={{ height: "7vh" }}>
                    <label className={styles.viewItemLabel}>
                      {account.name}
                    </label>
                  </div>
                </Grid>
              );
            })}
          </CardDisplayView>
        );
      }
    } else {
      return (
        <EmptyPageView
          message={`
            It looks like there are no accounts available to connect with at the moment. 
            Please check back later or explore other ways to expand your network.
          `}
        />
      );
    }
  }
};

export default AccountPage;
