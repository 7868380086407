import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import { Tooltip } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import EmptyPageView from "../component/EmptyPageView";
import ScrollableListView from "../component/ScrollableListView";
import CardDisplayView from "../component/CardDisplayView";
import ControlButtonView from "../component/ControlButtonView";
import {
  addRequests,
  excludeRequest,
  addContact,
} from "../redux/partnershipSlice";
import {
  fetchRequests,
  enableContactRequest,
  suspendContactRequest,
} from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { phraseMatches } from "../../common/utils/SearchUtils";
import { ViewMode } from "../utils/ViewUtils";
import styles from "../style/partnership.module.css";

const RequestControls = (props) => {
  const dispatch = useDispatch();
  const { fontSize, uuid, account, editable } = props;

  if (editable) {
    return [
      <ControlButtonView
        title="Confirm connection acceptance"
        message={`
            You're about to accept a contact request from "${account.name}". 
            You can suspend the connection later if necessary. Do you want to proceed?
          `}
        onClick={() => {
          showLoading();
          enableContactRequest(uuid)
            .then(() => {
              dispatch(excludeRequest(uuid));
              dispatch(
                addContact({
                  uuid: uuid,
                  active: true,
                  editable: true,
                  account: account,
                })
              );
            })
            .finally(hideLoading);
        }}
      >
        <CheckBoxOutlinedIcon fontSize={fontSize} htmlColor="#0D6986" />
      </ControlButtonView>,
      <ControlButtonView
        title="Decline connection request"
        message={`
            You're about to reject "${account.name}" contact request. You can 
            enable the connection later if necessary. Do you want to proceed?
          `}
        onClick={() => {
          showLoading();
          suspendContactRequest(uuid)
            .then(() => {
              dispatch(excludeRequest(uuid));
              dispatch(
                addContact({
                  uuid: uuid,
                  active: false,
                  editable: true,
                  account: account,
                })
              );
            })
            .finally(hideLoading);
        }}
      >
        <DisabledByDefaultOutlinedIcon
          fontSize={fontSize}
          htmlColor="#0D6986"
        />
      </ControlButtonView>,
    ];
  } else {
    return [
      <div />,
      <Tooltip
        sx={{
          marginRight: "1vh",
          marginTop: fontSize === "small" ? "1vh" : 0,
        }}
        placement="left"
        title={`The contact request for "${account.name}" has been posted.`}
      >
        <HelpCenterOutlinedIcon fontSize={fontSize} htmlColor={"#0D6986"} />
      </Tooltip>,
    ];
  }
};

const RequestPage = () => {
  const dispatch = useDispatch();
  const { viewMode, searchPhrase } = useSelector((state) => state.property);
  const { requests } = useSelector((state) => state.partnership);

  useEffect(() => {
    if (!requests) {
      showLoading();
      fetchRequests()
        .then((data) => dispatch(addRequests(data)))
        .finally(hideLoading);
    }
  });

  if (requests) {
    const actualRequests =
      searchPhrase !== undefined
        ? requests.filter((request) =>
            phraseMatches(request.account.name, searchPhrase)
          )
        : requests;
    if (actualRequests.length > 0) {
      if (viewMode === ViewMode.LIST) {
        return (
          <ScrollableListView>
            {actualRequests.map((request) => {
              const account = request.account;
              return (
                <div className={styles.listViewContent}>
                  <div className={styles.listViewDetails}>
                    <div className={styles.listViewLogo}>
                      <img
                        className={styles.listViewImage}
                        src={account.logo}
                        alt={""}
                      />
                    </div>
                    <div className={styles.listViewName}>
                      <label className={styles.viewItemLabel}>
                        {account.name}
                      </label>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <RequestControls
                      fontSize="medium"
                      uuid={request.uuid}
                      editable={request.editable}
                      account={request.account}
                    />
                  </div>
                </div>
              );
            })}
          </ScrollableListView>
        );
      } else if (viewMode === ViewMode.CARD) {
        return (
          <CardDisplayView>
            {actualRequests.map((request) => {
              const account = request.account;
              return (
                <Grid
                  className={styles.cardViewContent}
                  item
                  size={2}
                  key={"account.uuid"}
                >
                  <div style={{ flex: 1, display: "flex" }}>
                    <div className={styles.cardViewControls} />
                    <div className={styles.cardViewLogo}>
                      <img
                        className={styles.cardViewImage}
                        src={account.logo}
                        alt={""}
                      />
                    </div>
                    <div className={styles.cardViewControls}>
                      <RequestControls
                        fontSize="small"
                        uuid={request.uuid}
                        editable={request.editable}
                        account={request.account}
                      />
                    </div>
                  </div>
                  <div style={{ height: "7vh" }}>
                    <label className={styles.viewItemLabel}>
                      {account.name}
                    </label>
                  </div>
                </Grid>
              );
            })}
          </CardDisplayView>
        );
      }
    } else {
      return (
        <EmptyPageView
          message={`
            It looks like you don’t have any active contact requests
            right now. Explore and connect with new accounts to get started.
          `}
        />
      );
    }
  }
};

export default RequestPage;
