import { useState } from "react";
import { NavLink } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import ChromaImageView from "./ChromaImageView";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import styles from "../style/image.module.css";

const ImageViewPicker = (props) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);

  return (
    <div className={styles.cover}>
      <Dialog width={"50vw"} open={open} onClose={() => setOpen(false)}>
        <DialogContent sx={{ width: "40vw" }}>
          <TextField
            sx={{ width: "39vw" }}
            variant="standard"
            label="Cover image url"
            onChange={(event) => {
              setImage(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold" }}
            onClick={() => {
              setOpen(false);
              props.onSelect(image);
            }}
          >
            select
          </Button>
        </DialogActions>
      </Dialog>
      <NavLink to={"#"} className={styles.picker} onClick={() => setOpen(true)}>
        <PhotoOutlinedIcon sx={{ fontSize: "7vw" }} htmlColor="#BBBBBB" />
        <label className={styles.label}>click to load image</label>
      </NavLink>
    </div>
  );
};

const CoverImagePicker = (props) => {
  if (props.src) {
    return (
      <div style={{ marginRight: "1vw" }}>
        <ChromaImageView
          src={props.src}
          defaultColor="#EEEEEE"
          size="15vw"
          onRemove={props.onRemove}
        />
      </div>
    );
  } else {
    return <ImageViewPicker onSelect={props.onSelect} />;
  }
};

export default CoverImagePicker;
