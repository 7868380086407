import PanelView from "../../navigation/component/NavigationPanelView";
import React from "react";

const DashboardPage = () => {
  return (
    <div>
      <PanelView />
    </div>
  );
};

export default DashboardPage;
