import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import ChromaView from "./ChromaView";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import styles from "../style/summary.module.css";

const controlButton = { backgroundColor: "#fff", padding: 4 };

const ControlView = (props) => {
  const [show, setShow] = useState(false);

  if (show) {
    return (
      <div className={styles.controls} onMouseLeave={() => setShow(false)}>
        <IconButton size="small" style={controlButton}>
          <ZoomOutMapIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
        <IconButton size="small" style={controlButton}>
          <ModeEditOutlineOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
        <IconButton size="small" style={controlButton}>
          <DeleteOutlinedIcon htmlColor="#0d6986" fontSize="small" />
        </IconButton>
      </div>
    );
  } else {
    return (
      <IconButton
        size="small"
        style={{
          position: "absolute",
          right: 3,
          top: 3,
          backgroundColor: "#fff",
          padding: 3,
        }}
        onClick={() => setShow(true)}
      >
        <MoreVertIcon htmlColor="#0d6986" fontSize="small" />
      </IconButton>
    );
  }
};

const SummaryCardView = (props) => {
  return (
    <Grid item size={2}>
      <ChromaView src={props.image}>
        <div className={styles.image}>
          <img
            style={{ maxWidth: "13vw", maxHeight: "30vh" }}
            src={props.image}
            alt=""
          />
          <Tooltip
            arrow
            title={props.type ? "standalone product" : "group of products"}
            placement="right"
            size="small"
            className={styles.tooltip}
          >
            <CategoryOutlinedIcon htmlColor="#0d6986" fontSize="small" />
          </Tooltip>
          <ControlView />
        </div>
        <div className={styles.message}>
          <label className={styles.name}>
              {
                  props.name?.length > 25 ? (
                      `${props.name?.substring(0, 20)}...`
                  ) : (
                      props.name
                  )
              }
          </label>
          <label className={styles.description}>
            {props.description?.substring(0, 95)}{" "}
            {props.description?.length >= 95 && "..."}
          </label>
        </div>
      </ChromaView>
    </Grid>
  );
};

export default SummaryCardView;
