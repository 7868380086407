import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Transition } from "../utils/EffectUtils";
import IconButton from "@mui/material/IconButton";

const ControlButtonView = (props) => {
  const component = "alert-dialog-slide-description-" + Math.random();
  const [active, setActive] = useState(false);

  const onDialogClose = () => {
    setActive(false);
  };

  return (
    <div>
      <IconButton onClick={() => setActive(true)}>{props.children}</IconButton>
      <Dialog
        open={active}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDialogClose}
        aria-describedby={component}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={component}>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose}>Dismiss</Button>
          <Button
            onClick={() => {
              onDialogClose();
              props.onClick();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ControlButtonView;
