import React from "react";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const RetailPeriodView = (props) => {
  const dateFormat = "YYYY-MM-DD";

  const format = (date) => {
    return moment(date).format(dateFormat);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2.5vh",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ flex: 1 }}
          format={dateFormat}
          label="Start Retail Date"
          defaultValue={props.startRetailDate}
          onChange={(event) => {
            const date = event ? format(event.toDate()) : event;
            console.log(`start retail date: ${date}`);
            props.onStartRetailDateSelect(date);
          }}
          slotProps={{
            field: { clearable: true },
            textField: { size: "small" },
          }}
        />
        <div style={{ width: "1vw" }} />
        <DatePicker
          sx={{ flex: 1 }}
          format={dateFormat}
          label="End Retail Date"
          disablePast={true}
          defaultValue={props.endRetailDate}
          onChange={(event) => {
            const date = event ? format(event.toDate()) : event;
            props.onEndRetailDateSelect(date);
          }}
          slotProps={{
            field: { clearable: true },
            textField: { size: "small" },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default RetailPeriodView;
