import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TuneIcon from "@mui/icons-material/Tune";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import EmptyPageView from "../component/EmptyPageView";
import ScrollableListView from "../component/ScrollableListView";
import CardDisplayView from "../component/CardDisplayView";
import ControlButtonView from "../component/ControlButtonView";
import IconButton from "@mui/material/IconButton";
import { ViewMode } from "../utils/ViewUtils";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import {
  enableContactRequest,
  fetchContacts,
  suspendContactRequest,
} from "../client/AxiosClient";
import {
  addContact,
  addContacts,
  excludeContact,
} from "../redux/partnershipSlice";
import { phraseMatches } from "../../common/utils/SearchUtils";
import styles from "../style/partnership.module.css";

const ControlButton = (props) => {
  const { uuid, account, active, editable } = props.contact;
  const dispatch = useDispatch();

  const renderTitle = () => {
    if (active) {
      return `Suspend ${account.name}'s connection`;
    } else {
      return `Enable ${account.name}'s connection`;
    }
  };

  const renderMessage = () => {
    if (active) {
      return `
        You're about to suspend connection with contact "${account.name}" contact. 
        You can enable the connection later if necessary. Do you want to continue?
      `;
    } else {
      return `
        You're about to enable "${account.name}" contact. This action will make the
        connection active. If necessary can suspend later. Do you want to continue?
      `;
    }
  };

  const triggerAction = () => {
    if (active) {
      return suspendContactRequest(uuid);
    } else {
      return enableContactRequest(uuid);
    }
  };

  if (editable) {
    return (
      <ControlButtonView
        title={renderTitle()}
        message={renderMessage()}
        onClick={() => {
          showLoading();
          triggerAction()
            .then(() => {
              dispatch(excludeContact(uuid));
              dispatch(
                addContact({
                  uuid: uuid,
                  active: !active,
                  editable: true,
                  account: account,
                })
              );
            })
            .finally(hideLoading);
        }}
      >
        <TuneIcon fontSize={props.fontSize} htmlColor="#0D6986" />
      </ControlButtonView>
    );
  } else {
    return (
      <Tooltip
        sx={{
          marginRight: "1vh",
          marginTop: props.fontSize === "small" ? "1vh" : 0,
        }}
        title={`
          The contact has been suspended by "${account.name}". You will have to 
          contact ${account.name}'s administrator to enable the relation.
        `}
        placement="left"
      >
        <HelpCenterOutlinedIcon htmlColor={"#0D6986"} />
      </Tooltip>
    );
  }
};

const ContactPage = () => {
  const dispatch = useDispatch();
  const { viewMode, searchPhrase } = useSelector((state) => state.property);
  const { contacts } = useSelector((state) => state.partnership);

  useEffect(() => {
    if (!contacts) {
      showLoading();
      fetchContacts()
        .then((data) => dispatch(addContacts(data)))
        .finally(hideLoading);
    }
  });

  if (contacts) {
    const actualContacts =
      searchPhrase !== undefined
        ? contacts.filter((contact) =>
            phraseMatches(contact.account.name, searchPhrase)
          )
        : contacts;
    if (actualContacts.length > 0) {
      if (viewMode === ViewMode.LIST) {
        return (
          <ScrollableListView>
            {actualContacts.map((contact) => {
              const account = contact.account;
              return (
                <div className={styles.listViewContent}>
                  <div className={styles.listViewDetails}>
                    <div className={styles.listViewLogo}>
                      <img
                        className={styles.listViewImage}
                        src={account.logo}
                        alt={""}
                      />
                    </div>
                    <div className={styles.listViewName}>
                      <label className={styles.viewItemLabel}>
                        {account.name}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "13vw",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className={styles.listViewStatus}
                      style={{
                        backgroundColor: contact.active ? "#E5F2E5" : "#FBF3E5",
                        color: contact.active ? "#329932" : "#D98E04",
                      }}
                    >
                      {contact.active ? "active" : "suspended"}
                    </label>
                    <ControlButton fontSize="medium" contact={contact} />
                  </div>
                </div>
              );
            })}
          </ScrollableListView>
        );
      } else if (viewMode === ViewMode.CARD) {
        return (
          <CardDisplayView>
            {actualContacts.map((contact) => {
              const account = contact.account;
              return (
                <Grid
                  className={styles.cardViewContent}
                  item
                  size={2}
                  key={account.uuid}
                >
                  <div style={{ flex: 1, display: "flex" }}>
                    <div className={styles.cardViewControls}>
                      <div>
                        <IconButton disabled={true}>
                          <div
                            style={{
                              width: "1.2vw",
                              height: "1.2vw",
                              borderRadius: "1vw",
                              backgroundColor: contact.active
                                ? "#329932"
                                : "#D98E04",
                            }}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div className={styles.cardViewLogo}>
                      <img
                        className={styles.cardViewImage}
                        src={account.logo}
                        alt={""}
                      />
                    </div>
                    <div
                      className={styles.cardViewControls}
                      style={{ paddingTop: contact.editable ? 0 : "1vh" }}
                    >
                      <ControlButton fontSize="small" contact={contact} />
                    </div>
                  </div>
                  <div style={{ height: "7vh" }}>
                    <label className={styles.viewItemLabel}>
                      {account.name}
                    </label>
                  </div>
                </Grid>
              );
            })}
          </CardDisplayView>
        );
      }
    } else {
      return (
        <EmptyPageView
          message={`It looks like you don’t have any contacts at the moment.
            Explore and connect with new accounts to get started.`}
        />
      );
    }
  }
};

export default ContactPage;
