import Grid from "@mui/material/Grid2";

const CardDisplayView = (props) => {
  return (
    <Grid sx={{ flex: 1 }} container rowSpacing={2} columnSpacing={2}>
      {props.children}
    </Grid>
  );
};

export default CardDisplayView;
