import { useState } from "react";
import { NavLink } from "react-router-dom";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

const FilterButton = (props) => {
  const [display, setDisplay] = useState(false);

  return (
    <NavLink
      to={"#"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        width: "4.7vw",
        marginLeft: "1vw",
        textDecoration: "none",
        paddingRight: "0.3vw",
        borderRadius: 10,
        border: display ? "1.5px solid #0d6986" : "1.5px solid #707070",
        fontWeight: "bold",
        fontSize: "0.9vw",
        color: display ? "#0d6986" : "#707070",
      }}
      onClick={() => {
        const value = !display;
        setDisplay(value);
        props?.onChange(value);
      }}
    >
      <FilterListRoundedIcon htmlColor={display ? "#0d6986" : "#707070"} />
      Filter
    </NavLink>
  );
};

export default FilterButton;
