import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import reportWebVitals from "./reportWebVitals";
import ThreeCirclesLoading from "./common/dialog/ThreeCirclesLoading";
import GenericScopeDialog from "./common/dialog/GenericScopeDialog";
import CookieConsentDialog from "./common/dialog/CookieConsentDialog";
import ShadworkPage from "./ShadworkPage";
import HomePage from "./welcome/page/HomePage";
import AuthorisePage from "./welcome/page/AuthorisePage";
import NotFoundPage from "./common/page/NotFoundPage";
import PricingPage from "./welcome/page/PricingPage";
import RegistrationPage from "./registration/page/RegistrationPage";
import DashboardPage from "./dashboard/page/DashboardPage";
import PartnershipPage from "./partnership/page/PartnershipPage";
import PartnershipAccountPage from "./partnership/page/AccountPage";
import PartnershipContactPage from "./partnership/page/ContactPage";
import PartnershipRequestPage from "./partnership/page/RequestPage";
import ResourceWrapperPage from "./resource/page/ResourceWrapperPage";
import ResourcePage from "./resource/page/ResourcePage";
import ResourceProfilePage from "./resource/page/ResourceProfilePage";
import CreateProductPage from "./resource/page/CreateProductPage";
import CreateGroupPage from "./resource/page/CreateGroupPage";
import { Path as RegistrationPath } from "./registration/utils/PathUtils";
import { Path as PartnershipPath } from "./partnership/utils/PathUtils";
import { Path as ResourcePath } from "./resource/utils/PathUtils";

const dummySlice = createSlice({
  name: "dummySlice",
  initialState: {
    type: undefined,
  },
  reducers: {},
});

const store = configureStore({
  reducer: {
    dummySlice: dummySlice,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThreeCirclesLoading />
      <GenericScopeDialog />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<ShadworkPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/authorise" element={<AuthorisePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path={RegistrationPath.GENERIC} element={<RegistrationPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path={PartnershipPath.BASE} element={<PartnershipPage />}>
          <Route
            path={PartnershipPath.ACCOUNT}
            element={<PartnershipAccountPage />}
          />
          <Route
            path={PartnershipPath.CONTACT}
            element={<PartnershipContactPage />}
          />
          <Route
            path={PartnershipPath.REQUEST}
            element={<PartnershipRequestPage />}
          />
        </Route>
        <Route path={ResourcePath.BASE} element={<ResourceWrapperPage />}>
          <Route path={ResourcePath.RESOURCE} element={<ResourcePage />} />
          <Route path={ResourcePath.RESOURCE_PROFILE} element={<ResourceProfilePage />} />
          <Route
            path={ResourcePath.CREATE_PRODUCT}
            element={<CreateProductPage />}
          />
          <Route
            path={ResourcePath.CREATE_GROUP}
            element={<CreateGroupPage />}
          />
        </Route>
      </Routes>
      <CookieConsentDialog />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
