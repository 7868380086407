import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import SearchView from "../../navigation/component/SearchView";
import FilterButton from "../../navigation/component/FilterButton";
import ProfileCardView from "../component/ProfileCardView";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import { searchResources } from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import styles from "../style/resource.module.css";

const FilterComponent = (props) => {
  const [expanded, setExpanded] = useState(true);

  const LabelIcon = () => {
    if (expanded) {
      return (
        <KeyboardDoubleArrowUpOutlinedIcon
          fontSize={"small"}
          htmlColor={"#0D6986"}
        />
      );
    } else {
      return (
        <KeyboardDoubleArrowDownOutlinedIcon
          fontSize={"small"}
          htmlColor={"#0D6986"}
        />
      );
    }
  };

  return (
    <div>
      <div className={styles.filterComponent}>
        <label className={styles.filterLabel}>{props.label}</label>
        <IconButton size={"small"} onClick={() => setExpanded(!expanded)}>
          <LabelIcon />
        </IconButton>
      </div>
      <div style={{ display: expanded ? "contents" : "none" }}>
        {props.children}
      </div>
    </div>
  );
};

const AccountFilterComponent = (props) => {
  const [owners, setOwners] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const mappedOwners = {};
    props.resources
      .map((resource) => resource.owner)
      .forEach((owner) => (mappedOwners[owner.uuid] = owner));
    setOwners(Object.values(mappedOwners));
  }, [props.resources]);

  return (
    <FilterComponent label={"Account"}>
      {owners.map((owner) => {
        const active = filters.some((uuid) => owner.uuid === uuid);
        return (
          <NavLink
            to={"#"}
            style={{
              border: `1px solid ${active ? "#0D6986" : "#5EA6C5"}`,
              backgroundColor: active ? "#BFF9E4" : "#ffffff",
              color: active ? "#0D6986" : "#5EA6C5",
            }}
            className={styles.item}
            onClick={() => {
              if (filters.some((uuid) => uuid === owner.uuid)) {
                setFilters(filters.filter((uuid) => uuid !== owner.uuid));
              } else {
                setFilters([...filters, owner.uuid]);
              }
            }}
          >
            <img
              src={owner.logo}
              style={{ maxWidth: "3vw", maxHeight: "3vh", marginRight: "1vw" }}
              alt=""
            />
            {owner.name}
          </NavLink>
        );
      })}
    </FilterComponent>
  );
};

const ResourcesCardModeView = ({ phrase, showFilter, resources }) => {
  const [ownersFilter, setOwnersFilter] = useState([]);

  if (resources) {
    if (resources.length === 0) {
      return (
        <div className={styles.empty}>
          <label className={styles.label}>
            There are currently no goods of group of goods available to you.
            Please check back later or contact support if you have any
            questions.
          </label>
        </div>
      );
    } else {
      return [
        <Grid container spacing={2} sx={{ flex: 1 }}>
          {resources
            .filter((resource) => {
              return (
                ownersFilter.length === 0 ||
                ownersFilter.some((uuid) => uuid === resource.owner.uuid)
              );
            })
            .map((resource) => {
              return <ProfileCardView resource={resource} />;
            })}
        </Grid>,
        <Grid
          container
          spacing={2}
          size={2}
          sx={{ display: showFilter ? undefined : "none" }}
        >
          <Grid size={2} sx={{ flex: 1 }}>
            <div className={styles.filter}>
              <AccountFilterComponent
                resources={resources}
                onChange={setOwnersFilter}
              />
            </div>
          </Grid>
        </Grid>,
      ];
    }
  }
};

const ResourceProfilePage = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [isScrolledUp, setScrolledUp] = useState(true);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    showLoading();
    searchResources().then(setResources).finally(hideLoading);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          height: isScrolledUp ? "3.5vw" : "0",
          transform: isScrolledUp ? "translateY(0)" : "translateY(-100%)",
          transition:
            "transform 0.3s ease-in, transform 0.3s ease-out, height 0.3s",
          transitionBehavior: "allow-discrete",
          padding: "0 1vw",
        }}
      >
        <label className={styles.label}>Resources</label>
      </div>
      <div className={styles.content}>
        <div className={styles.control}>
          <div style={{ width: "20vw" }}>
            <SearchView
              onSearch={setPhrase}
              onSearchCancel={() => setPhrase("")}
            />
          </div>
          <div style={{ display: "flex" }}>
            <FilterButton onChange={setShowFilter} />
          </div>
        </div>
        <div
          className={styles.scrollableContent}
          onScroll={(event) => {
            if (event.currentTarget.scrollTop === 0) {
              setScrolledUp(true);
            } else {
              setScrolledUp(false);
            }
          }}
        >
          <Grid container spacing={2} sx={{ flex: 1 }}>
            <ResourcesCardModeView
              showFilter={showFilter}
              phrase={phrase}
              resources={resources}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ResourceProfilePage;
