const buildMessageBundle = (general, locales) => {
  if (general || locales?.length > 0) {
    const message = {};
    if (general) {
      message.default = general;
    }
    if (locales?.length > 0) {
      const bundles = [];
      locales.forEach((locale) => {
        bundles.push({ locale: locale.speech, value: locale.value });
      });
      message.bundles = bundles;
    }
    return message;
  }
};

const buildImage = (cover, images) => {
  if (cover || images?.length > 0) {
    return { cover, images };
  }
};

export { buildMessageBundle, buildImage };
